export default [
  {
    label: 'Key',
    field: 'key',
  },
  {
    label: 'Value',
    field: 'value',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
