<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-flex">
          <b-form-file
            v-model="file"
            button-only
            button-variant="primary"
            class="mt-auto mt-1"
            browse-text="Upload CSV"
            v-b-tooltip.focus.v-primary
            title="Please don't use in keys and values symbols: '@#%*!' and others. Use only alphabetical characters and numbers"
          />
          <feather-icon
            icon="InfoIcon"
            size="22"
            class="ml-1"
            v-b-tooltip.hover.v-primary
            title="Please don't use in keys and values symbols: '@#%*!' and others. Use only alphabetical characters and numbers"
          />
        </div>

        <small v-if="isFileUpload" class="text-primary">
          New keys added: {{ fileData.createdLinks }}. Total: {{ fileData.totalRows }}
        </small>
        <small v-if="isFileUpload && fileData.errors.length" class="text-danger">
          <span v-for="error in fileData.errors" :key="error">{{ error }}</span>
        </small>
      </b-col>

      <b-col>
        <b-button class="mt-auto" type="submit" variant="primary" @click="submitCSV">
          <feather-icon icon="FileIcon" size="14" />
          <span> Submit csv</span>
        </b-button>

        <b-button class="mt-auto ml-1" variant="warning" @click="clearFiles">
          <feather-icon icon="TrashIcon" size="14" />
          <span> Clear File input </span>
        </b-button>
      </b-col>

      <b-col>
        <div class="d-flex  justify-content-end flex-wrap mb-2">
          <b-button class="mt-auto" type="submit" variant="primary" @click="create">
            <feather-icon icon="PlusIcon" size="14" />
            <span> Create Link</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <AppTable
      :rows="rows"
      :columns="columns"
      :pagination="{
        count: keys.count,
        limit: keys.limit,
        page: keys.page,
      }"
      :is-loading="requestInProgress"
      :is-pagination="true"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col md="11" xl="10" class="mb-1">
            <b-form-group>
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="Search link" type="text" />
            </b-form-group>
          </b-col>
          <b-col md="1" xl="1" class="mb-1 mt-auto pb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #default="{ column, row, formattedRow }">
        <span v-if="column.field === 'action'" class="d-flex">
          <b-button class="mt-auto text-white ml-1" type="button" variant="primary" @click="edit(row)">
            <span class="text-white"> Edit </span>
          </b-button>

          <b-button class="mt-auto text-white ml-1" type="button" variant="danger" @click="deleteKey(row)">
            <span class="text-white"> Delete </span>
          </b-button>
        </span>

        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton, BCol, BRow, BFormFile, BFormInput, BFormGroup, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import doCopy from '@/mixins/doCopy'

import AppTable from '@/components/AppTable.vue'
import columns from './config/tableConfig'
import { cleanObj } from '@/tools/helpers'

export default {
  name: 'AnalyticsView',
  components: {
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    AppTable,
    BButton,
    BFormFile,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [waitRequest, validationError, doCopy],
  data() {
    return {
      columns,
      file: null,
      searchTerm: '',
      isFileUpload: false,
      fileData: {
        createdLinks: 0,
        errors: [],
        skippedLinks: 0,
        totalRows: 0,
      },
    }
  },

  computed: {
    ...mapGetters({
      keys: 'trackableLinks/keys',
    }),

    rows() {
      return this.transformData(this.keys.items || [])
    },
  },
  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchTrackableLinks: 'trackableLinks/fetchTrackableLinks',
      uploadCSVTrackableLinks: 'trackableLinks/uploadCSVTrackableLinks',
      deleteTrackableLinks: 'trackableLinks/deleteTrackableLinks',
    }),

    initState() {
      this.getLinks({ ...this.$route.query })
    },
    clearFiles() {
      this.file = null
    },
    submitCSV() {
      if (!this.file) return

      const formData = new FormData()
      formData.append('file', this.file)

      this.waitRequest(() =>
        this.uploadCSVTrackableLinks(formData)
          .then(response => {
            this.isFileUpload = true
            this.fileData = response?.data || {}
            const query = this.createQueryConfig()
            this.fetchTrackableLinks(query).catch(this.checkErrorsAlert)
          })
          .catch(this.checkErrors),
      )
    },

    create() {
      this.$router.push({ path: '/analytics/create' })
    },

    edit(row) {
      this.$router.push({ path: `/analytics/edit/${row.id}` })
    },

    transformData(rows) {
      return rows.map(row => ({
        ...row,
      }))
    },

    deleteKey(key) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, delete ''${key.key}_${key.value}''!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (!result.isConfirmed) return

        this.removeKey(key.id)
      })
    },

    removeKey(keyId) {
      this.deleteTrackableLinks({ id: keyId })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Key has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(this.checkErrors)
    },

    getLinks(query = {}) {
      return this.waitRequest(() => this.fetchTrackableLinks(query).catch(this.checkErrorsAlert))
    },

    search() {
      const query = this.createQueryConfig({ search: this.searchTerm, page: 1 })

      return this.getLinks(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getLinks(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getLinks(query)
    },

    createQueryConfig(config) {
      const queryConfig = {
        page: this.keys.page || 1,
        limit: this.keys.limit,
        search: this.searchTerm,

        ...config,
      }

      this.writeHistory(queryConfig)

      return queryConfig
    },

    writeHistory(query) {
      this.$router.replace({
        path: this.$route.path,
        query: cleanObj(query),
        replace: true,
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>
